"use client"

import { useTrackerDeleteMutation, useTrackerList } from "./lib/tracker";
import { format } from "date-fns";
import Link from "next/link";
import { useMemo, useState } from "react";
import { AddTrackerModal } from "./_components/Tracker/Add";
import { EditTrackerModal } from "./_components/Tracker/Edit";
import { Button } from "./_components/Button";
import { ITracker } from "@/server/models/tracker";

export default function Home() {
  const { trackers, query: { refetch, isFetched } } = useTrackerList({});

  const [search, setSearch] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [editTrackerId, setEditTrackerId] = useState<string | null>(null);

  const trackerDeleteMutation = useTrackerDeleteMutation({
    onSuccess() {
      refetch();
    }
  });

  const handleDelete = (tracker: ITracker) => {
    if (confirm(`Are you sure you want to delete ${tracker.name}`)) {
      trackerDeleteMutation.mutate({ id: tracker.id });
    }
  }

  const { startOfWeek } = getWeekRange();
  const updatesThisWeek = trackers.filter(tracker => {
    if (tracker.date) {
      const currentDate = new Date(tracker.date);
      return currentDate >= startOfWeek;
    }
    return false;
  }).length;

  const searchedTrackers = useMemo(() => {
    if (!search) return trackers;
    return trackers.filter(tracker => `${tracker.url.toLowerCase()} ${tracker.name.toLowerCase()}`.includes(search.toLowerCase()))
  }, [trackers, search]);

  return (
    <>
      <section className="grid grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow text-center">
          <h3 className="text-lg font-semibold">Total URLs Tracked</h3>
          <p className="text-2xl mt-2">{isFetched ? trackers.filter(tracker => tracker.enabled).length : <span className="animate-pulse">...</span>}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow text-center">
          <h3 className="text-lg font-semibold">Updates This Week</h3>
          <p className="text-2xl mt-2">{isFetched ? updatesThisWeek : <span className="animate-pulse">...</span>}</p>
        </div>
        {/* <div className="bg-white p-6 rounded-lg shadow text-center">
          <h3 className="text-lg font-semibold">Pending Actions</h3>
          <p className="text-2xl mt-2">{isFetched ? '3' : <span className="animate-pulse">...</span>}</p>
        </div> */}
      </section>

      <section className="mt-8 bg-white p-6 rounded-lg shadow">
        <div className="w-full flex justify-between mb-4 items-center">
          <div className="flex flex-none gap-6 items-center">
            <h2 className="text-xl font-semibold">Trackers</h2>
            <input type="text" placeholder="Search" className="flex-grow px-4 border rounded py-2" value={search} onChange={e => setSearch(e.currentTarget.value)} />
          </div>
          <div className="flex flex-none gap-6">
            <Button onClick={() => setShowAddModal(true)}>Add</Button>
          </div>
        </div>
        <div className="overflow-auto">
          <table className="w-full table-fixed border-collapse border min-w-[800px]">
            <thead>
              <tr className="bg-gray-200 text-left">
                <th className="p-2" style={{ width: '200px' }}>Name</th>
                <th className="p-2">URL</th>
                <th className="p-2" style={{ width: '130px' }}>Last Updated</th>
                <th className="p-2" style={{ width: '150px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {searchedTrackers.map(tracker => {
                return (
                  <tr key={tracker.id} className="hover:bg-gray-100">
                    <td className="p-2 border-b truncate">{tracker.name}</td>
                    <td className="p-2 border-b truncate"><a target="_blank" href={tracker.url}>{tracker.url}</a></td>
                    <td className="p-2 border-b">{tracker.date ? format(tracker.date, 'MM/dd/yyyy') : '-'}</td>
                    <td className="p-2 border-b">
                      <div className="flex gap-2">
                        <Link href={`/tracker/${tracker.id}`} className="text-blue-500 hover:underline">View</Link>
                        <div onClick={() => setEditTrackerId(tracker.id)} className="text-blue-500 hover:underline cursor-pointer">Edit</div>
                        <div onClick={() => handleDelete(tracker)} className="text-blue-500 hover:underline cursor-pointer">Delete</div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>

      {showAddModal && <AddTrackerModal onUpdate={() => {refetch()}} onClose={() => setShowAddModal(false)} />}
      {editTrackerId && <EditTrackerModal trackerId={editTrackerId} onUpdate={() => refetch()} onClose={() => setEditTrackerId(null)} />}
    </>
  );
}

// Function to get the start of the week (Monday) and end of the week (Sunday)
function getWeekRange() {
  const currentDate = new Date();
  const firstDayOfWeek = currentDate.getDate() - currentDate.getDay() + 1; // Get Monday (start of the week)
  const lastDayOfWeek = firstDayOfWeek + 6; // Get Sunday (end of the week)

  const startOfWeek = new Date(currentDate.setDate(firstDayOfWeek));
  const endOfWeek = new Date(currentDate.setDate(lastDayOfWeek));

  // Set time to the start of the day for both dates
  startOfWeek.setHours(0, 0, 0, 0);
  endOfWeek.setHours(23, 59, 59, 999);

  return { startOfWeek, endOfWeek };
}

