"use client"

import { Modal } from '../Modal';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DEFAULT_TRACKER_INFO, formSchema, FormSchemaType, TrackerForm } from './Form';
import { useTrackerUpdateMutation } from '@/app/lib/tracker';

export interface IModalProps {
  onClose(): void;
  onUpdate(): void;
}

export function AddTrackerModal({ onClose, onUpdate }: IModalProps) {
  const mutation = useTrackerUpdateMutation({
    onSuccess() {
      onClose();
      onUpdate();
    }
  });

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: DEFAULT_TRACKER_INFO,
  });

  const handleSubmit = () => {
    if (!mutation.isLoading) {
      mutation.mutate(form.getValues());
    }
  };

  return (
    <Modal open onClose={onClose} title="Add Tracker">
      <TrackerForm form={form} onSubmit={handleSubmit} loading={mutation.isLoading} />
    </Modal>
  );
}
